import { lazy } from "react";
import { RouteObject } from "react-router-dom";

// pages
const ResourcesPage = lazy(() => import("@/pages/resources"));
const SingleResourcePage = lazy(() => import("@/pages/resources/SingleResourcePage"));

// helpers
import { EOrganizationAccessLevel } from "@/types/api";
import { renderWithSuspense } from "../utils";
import { ROUTES } from "../routes.constants";


export const RESOURCES_ROUTES: RouteObject[] = [
  {
    path: ROUTES.RESOURCES.ROOT,
    Component: renderWithSuspense(ResourcesPage, {
      permissions: { organization: [EOrganizationAccessLevel.EXTERNAL] }
    }),
  },
  {
    path: ROUTES.RESOURCES.SINGLE_RESOURCE,
    Component: renderWithSuspense(SingleResourcePage, {
      permissions: { organization: [EOrganizationAccessLevel.EXTERNAL] }
    }),
  }
];

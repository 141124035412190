export const ROUTES = {
  AUTH: {
    ROOT: "/auth",
    SIGN_IN: "/auth/sign-in",
    SIGN_UP: "/auth/sign-up/:token",
    RESET_PASSWORD: "/auth/reset-password",
    CREATE_NEW_PASSWORD: "/auth/set-password/:token",
  },
  USER_MANAGEMENT: {
    ROOT: "/user-management",
    CONTRACTORS: "/user-management/contractors",
    SINGLE_CONTRACTOR: "/user-management/contractors/:id",
    SINGLE_CONTRACTOR_USERS: "/user-management/contractors/:id/users",
    SINGLE_CONTRACTOR_TENDERS: "/user-management/contractors/:id/tenders",
  },
  RFP_MANAGEMENT: {
    ROOT: "/rfp-management",
    RFP_CREATE: "/rfp-management/create",
    SINGLE_RFP: {
      ROOT: "/rfp-management/:id",
      SUBMIT_TENDER: "/rfp-management/:id/tender/:tenderId/submit",
      STANDARDS_PROCEDURES: "/rfp-management/:id/standards-procedures",
      INFORMATION_OUTLINE: "/rfp-management/:id/information-outline",
      INITIATIVES: "/rfp-management/:id/initiatives/:initiativeId",
      INVITED_CONTRACTORS: "/rfp-management/:id/invited-contractors",
      COMMENTS: "/rfp-management/:id/comments",
      CREATE_REPORT: "/rfp-management/:id/create-report",
      SUBSCRIBERS: "/rfp-management/:id/subscribers"
    },
    RFP_TENDER: {
      ROOT: "/rfp-management/:id/tender/:tenderId",
      STANDARDS_PROCEDURES: "/rfp-management/:id/tender/:tenderId/standards-procedures",
      INITIATIVES: "/rfp-management/:id/tender/:tenderId/initiatives/:initiativeId",
      STEEL_CLEAN_UP: "/rfp-management/:id/tender/:tenderId/steel-clean-up",
      PROJECT_FEES: "/rfp-management/:id/tender/:tenderId/project-fees",
      DOCUMENTS: "/rfp-management/:id/tender/:tenderId/documents",
      BUDGET_SUMMARY: "/rfp-management/:id/tender/:tenderId/budget-summary",
      COMMENTS: "/rfp-management/:id/tender/:tenderId/comments",
      SCHEDULE_OF_EXECUTION: "/rfp-management/:id/tender/:tenderId/schedule-of-execution",
      CHANGE_ORDERS: "/rfp-management/:id/tender/:tenderId/change-order",
    },
  },
  TEMPLATES: {
    ROOT: "/templates",
    CREATE: "/templates/create",
    SINGLE_TEMPLATE: "/templates/:id",
  },
  PROFILE: {
    PROFILE_DETAILS: "/profile",
    CHANGE_PASSWORD: "/profile/change-password",
    NOTIFICATIONS: "/profile/notifications",
  },
  ACTIVITY_LOGS: {
    ROOT: "/activity-logs",
  },
  REPORTS: {
    ROOT: "/reports",
    SINGLE_REPORT: "/reports/:type/:id?",
  },
  RESOURCES: {
    ROOT: "/resources",
    SINGLE_RESOURCE: "/resources/single-resource",
  },
  NOT_FOUND: "/404",
};

import { lazy } from "react";
import { createBrowserRouter, RouterProvider, redirect, RouteObject } from "react-router-dom";

// layouts
const BaseLayout = lazy(() => import("@/layouts/BaseLayout"));

// not found
const NotFoundPage = lazy(() => import("../NotFoundPage"));

// routes
import {
  AUTH_ROUTES,
  PROFILE_ROUTES,
  USER_MANAGEMENT_ROUTES,
  RFP_MANAGEMENT_ROUTES,
  TEMPLATES_ROUTES,
  REPORTS_ROUTES,
  RESOURCES_ROUTES,
} from "./routes";
const ActivityLogs = lazy(() => import("../ActivityLogs"));

// helpers
import { EOrganizationAccessLevel } from "@/types/api";
import { privateRouteLoader, renderWithSuspense } from "./utils";
import { ROUTES } from "./routes.constants";

const PRIVATE_ROUTES: RouteObject[] = [
  {
    Component: renderWithSuspense(BaseLayout),
    loader: privateRouteLoader,
    children: [
      { path: "/", loader: () => redirect(ROUTES.RFP_MANAGEMENT.ROOT), },
      ...RFP_MANAGEMENT_ROUTES,
      ...USER_MANAGEMENT_ROUTES,
      ...TEMPLATES_ROUTES,
      ...PROFILE_ROUTES,
      ...REPORTS_ROUTES,
      ...RESOURCES_ROUTES,
      {
        path: ROUTES.ACTIVITY_LOGS.ROOT,
        Component: renderWithSuspense(
          ActivityLogs,
          { permissions: { organization: [EOrganizationAccessLevel.INTERNAL] } }
        ),
      },
      {
        path: "*",
        Component: renderWithSuspense(NotFoundPage)
      },
    ]
  },
];

const Router = () => (
  <RouterProvider
    router={createBrowserRouter([AUTH_ROUTES, ...PRIVATE_ROUTES])}
  />
);

export default Router;

const API_ENDPOINTS = {
  AUTH: {
    ROOT: "/auth",
    LOGIN: "/auth/sign-in",
    LOGOUT: "/auth/sigh-out",
    FORGOT_PASSWORD: "/auth/forgot-password",
    SET_NEW_PASSWORD: "/auth/set-password/:token",
    REFRESH: "/auth/refresh-tokens",
  },
  USERS: {
    ROOT: "/users",
    ME: "/users/me",
    INVITE: "/users/invites/:token",
    ACCEPT_INVITE: "/users/invites/:token/accept",
    NOTIFICATION_SETTINGS: "/users/email-notification-settings",
  },
  ORGANIZATIONS: {
    ROOT: "/organizations",
    SING_ORGANIZATION: "/organizations/:organizationId",
    USERS: "/organizations/:organizationId/users",
    SINGLE_USER: "/organizations/:organizationId/users/:userId",
    RFPS: "/organizations/:organizationId/rfps",
    REPORT: "/organizations/:organizationId/report"
  },
  TEMPLATES: {
    ROOT: "/templates",
    SINGLE: "/templates/:id",
    SINGLE_STATUS: "/templates/:id/status",
  },
  RFPS: {
    ROOT: "/rfps",
    SINGLE: "/rfps/:id",
    STATUS: "/rfps/:id/status",
    DECLINE: "/rfps/:id/decline",
    RE_RELEASE: "/rfps/:id/re-release",
    INITIATIVES: "/rfps/:id/initiatives",
    SINGLE_INITIATIVE: "/rfps/:id/initiatives/:initiativeId",
    ORGANIZATIONS: "/rfps/:id/organizations",
    TENDERS: "/rfps/:id/tenders",
    AWARDED_TENDERS: "/rfps/tenders/awarded/report",
    SINGLE_TENDER: "/rfps/:id/tenders/:tenderId",
    TENDERS_INITIATIVES: "/rfps/:id/tenders/:tenderId/initiatives",
    STEEL_CLEAN_UP: "/rfps/:id/tenders/:tenderId/steel-clean-ups",
    ADMINISTRATIVE_FEES: "/rfps/:id/tenders/:tenderId/administrative-fees",
    ATTACHMENTS: "/rfps/:id/tenders/:tenderId/attachments",
    BUDGET_SUMMARY: "/rfps/:id/tenders/:tenderId/budget-summary",
    SUBMIT: "/rfps/:id/tenders/:tenderId/submit",
    AWARD: "/rfps/:id/tenders/:tenderId/award",
    UNLOCK: "/rfps/:id/tenders/:tenderId/unlock",
    COMMENTS: "/rfps/:id/comments",
    REPORT: "/rfps/:id/report",
    KEY_DATES: "/rfps/:id/key-dates",
    TOGGLE_SUBSCRIBE: "/rfps/:id/toggle-subscribe",
    SINGLE_RFP_SUBSCRIBERS: "/rfps/:id/subscribers",
    SCHEDULE_OF_EXECUTION: {
      ROOT: "/rfps/:id/schedules-of-execution",
      SINGLE: "/rfps/:id/schedules-of-execution/:scheduleOfExecutionId",
      TEMPLATE: "/rfps/:id/schedules-of-execution/template",
      TASKS: "/rfps/:id/schedules-of-execution/tasks",
      VERSIONS: "/rfps/:id/schedules-of-execution/versions",
      VERSION: "/rfps/:id/schedules-of-execution/:versionId",
      DURATION_OF_PROJECT: "/rfps/:id/schedules-of-execution/:scheduleOfExecutionId/duration-of-project"
    },
    CHANGE_ORDERS: {
      ROOT: "/rfps/:id/change-orders",
      SINGLE: "/rfps/:id/change-orders/:changeOrderId",
      APPROVE: "/rfps/:id/change-orders/:changeOrderId/approve",
      DECLINE: "/rfps/:id/change-orders/:changeOrderId/decline",
    },
  },
  STORES: {
    ROOT: "/stores",
  },
  REPORTS: {
    BUDGET_SUMMARY: "reports/rfps/:rfpId/tenders/:tenderId/budget-summary",
    CONTRACTOR: "reports/contractor/:contractorId/report",
    RFP: "reports/rfp/:rfpId/report",
    TENDERS_AWARDED: "reports/tenders/awarded",
    DETAILS: "reports/rfp/:rfpId/details",
    PROJECT_HEAT_MAP: "reports/project-heatmap",
    PROJECT_HEAT_MAP_EXPORT: "reports/project-heatmap/export",
    AWARDED_TENDERS_TOTAL: "/reports/awarded-tenders-total",
    AWARDED_TENDERS_TOTAL_EXPORT: "/reports/awarded-tenders-total/export",
  },
  ACTION_LOGS: {
    ROOT: "/action-logs",
  },
  NOTIFICATIONS: {
    ROOT: "/notifications",
    TOGGLE_READ: "/notifications/toggle-read",
  },
  CLOUD_STORAGE: {
    PUBLIC_FILES: "/cloud-storage/public-files",
  },
};

export default API_ENDPOINTS;

import { ITemplate, ITemplateTaskUpdate } from "./templates.d";
import { IUser } from "./users.d";
import { IStore } from "./stores.d";
import { IOrganization } from "./organizations.d";
import { IAttachment } from "./IAttachment.d";
import { ERFPStatus, ETenderStatus } from "@/constants/rfpStatus.constant";
import { IComment } from "./comments.d";


export enum EShifts {
  DAY = "day",
  DAY_NIGHT = "day-night",
  NIGHT = "night",
}

export interface IRFPInitiative extends ITemplate {
  id: number;
  name: string;
  description: string;
}

export interface IRFPOrganizationsUpdate {
  organizationsIds: number[];
  status?: string;
}

export interface IRFPTender {
  id: number;
  updatedAt: string;
  createdAt: string;
  rfpId: number;
  organizationId: number;
  status: ETenderStatus;
  submittedAt: string;
  organization: IOrganization;
  budget: number;
  changeOrdersSummary: number;
  finalCost: number;
  commentsNumber: number;
}

export interface IRFP {
  id: number;
  store: IStore;
  shifts: EShifts;
  storeId: number;
  projectType: string;
  projectName: string;
  baysOfScope: string;
  commentsNumber: number;
  dueDate: string;
  projectAwardDate: string;
  contractorPreWalkValidation: string;
  scheduleOfExecutionDueDate: string;
  projectExecutionDate: string;
  storeKickOffMeetingDate: string;
  submittedTendersNumber: number;
  tendersNumber: number;
  fcpm: Omit<IUser, "receivedInvite" | "organization">;
  fcpmId: number;
  manager: Omit<IUser, "receivedInvite" | "organization">;
  managerId: number;
  status: ERFPStatus;
  attachments: IAttachment[];
  initiatives: IRFPInitiative[];
  invitedOrganizationsIds: number[];
  updatedAt: string;
  createdAt: string;
  tender?: Omit<IRFPTender, "commentsNumber">;
  tenders?: IRFPTender[];
  isCurrentUserSubscribed: boolean;
}

export interface IRFPOrganization extends IOrganization {
  tender: IRFPTender;
}

export interface IRFPInitiativeSingle {
  id: number;
  name: string;
  originalTemplateId: number;
  position: number;
  tasks: ITemplateTaskUpdate[];
}

export interface IRFPTenderReach extends IRFPTender {
  initiatives: {
    id: number;
    name: string;
    createdAt: string;
    updatedAt: string;
    position: number;
  }[];
}

export interface IRFPInitiativeCreate {
  status?: "draft";
  initiatives: IRFPInitiativeSingle[],
}

type IRFPCreateDraft = {
  storeId: number;
  projectType: string;
  projectName: string;
  daysOfScope: string;
  dueDate: string;
  projectAwardDate: string;
  contractorPreWalkValidationDate: string;
  scheduleOfExecutionDueDate: string;
  storeKickOffMeetingDate: string;
  projectExecutionDate: string;
  shifts: EShifts;
  fcpmId: number;
  managerId: number;
};

export type IRFPCreate = {
  [K in keyof IRFPCreateDraft]: IRFPCreateDraft[K] | null;
} & {
  status?: "draft";
  attachments: unknown[];
  existingAttachmentsIds?: number[];
  contractors?: number[];
  initiatives?: IRFPInitiativeCreate[];
}

interface IRfpTenderInitiativeTask extends ITemplateTaskUpdate {
  price: number;
  commentsNumber: number;
}

export interface IRfpTenderInitiative {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
  tasks: IRfpTenderInitiativeTask[];
}

export interface IRfpSteelCleanUp {
  tasks: {
    steelBinFreight: { value: number; comments: IComment[] };
    garbage: { value: number; comments: IComment[] };
    labour: { value: number; comments: IComment[] };
  };
  readonly total: number;
}

export interface IRfpTenderAdministrativeFees {
  administrative: { value: number; comments: IComment[] };
  detailReceiving: { value: number; comments: IComment[] };
  equipmentRental: { value: number; comments: IComment[] };
  projectManagement: { value: number; comments: IComment[] };
  trashRemoval: { value: number; comments: IComment[] };
  travel: { value: number; comments: IComment[] };
  validationWalk: { value: number; comments: IComment[] };
}

export interface IRfpTenderBudgetSummary {
  initiatives: {
    initiative: {
      id: number;
      name: string;
    };
    summary: {
      scopeOfWork: number;
      storeSpecific: number;
      subcontractorPricing: number;
      subtotal: number;
    };
  }[];
  subtotals: {
    scopeOfWork: number;
    storeSpecific: number;
    subcontractorPricing: number;
    subtotal: number;
  },
  fees: number;
  total: number;
}
